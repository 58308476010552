<!-- @format -->
<template>
  <div class="footer">
    <div class="footer-item">
      <div class="item1">
        <span class="text">产品</span>
        <a @click="goProduct('#survey')" class="text1">心理测评</a>
        <a @click="goProduct('#360content')" class="text1"
          >360</a
        >
        <a @click="goProduct('#exam')" class="text1">在线考试</a>
        <a @click="goProduct('#interview')" class="text1"
          >在线面试</a
        >
      </div>

      <div class="item2">
        <span class="text">应用场景</span>
        <a
          class="text1"
         
          @click="goScenarios('#Recruitment')"
          >批量招聘</a
        >
        <a
          class="text1"
        
          @click="goScenarios('#TalentSelection')"
          >人才盘点与选拔</a
        >
        <a
          class="text1"
       
          @click="goScenarios('#performance')"
          >绩效管理与评估</a
        >
      </div>

      <div class="item3">
        <span class="text">关于我们</span>
        <a @click="goJoin('#about')" class="text1">我们是谁</a>
        <a @click="goJoin('#join')" class="text1">加入我们</a>
      </div>

      <div class="item4">
        <span class="text">联系我们</span>
        <div class="text1">
          地址<br />北京市西城区新街口外大街2号院2号楼5层
        </div>
        <div class="text1">电话<br />010-65613920</div>
        <div class="text1">邮箱<br />lumengnan.rc@ciic.com.cn</div>
      </div>

    </div>
    <div class="copy-right">
        Copyright © ciictalent.com All Rights Reversed.
        <a href="https://beian.miit.gov.cn/" target="_blank">
            <span style="text-decoration:underline;color: white">京ICP备07008513号-12</span>
        </a>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502052327" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
            <img :src="image.beian" style="float: right;margin: 3px 0px 0px 0px;"/>
            <p style="float:left;height:20px;line-height:20px;margin: 5px 0px 0px 5px; color:white;">京公网安备 11010502052327号</p></a>
    </div>

  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { footItems } from "./config";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  name: "bottom-footer",
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
        footItems: footItems,
        image:{
            beian: require("@/assets/images/beian.png"),
        },
    });
    // 我们是谁
   
    const goJoin = (res) => {
     store.dispatch("setInfoNum", 3);
      router.push("/about");
      if (res) {
        checkScroll(res);
        setTimeout(() => {
          if (window.checkSTimer) {
            window.clearTimeout(window.checkSTimer);
          }
        }, 5000);
      }
    };
    const goProduct = (res) => {
      // document.querySelector(res).scrollIntoView()
      store.dispatch("setInfoNum", 1);
      router.push("/products");
      if (res) {
        checkScroll(res);
        setTimeout(() => {
          if (window.checkSTimer) {
            window.clearTimeout(window.checkSTimer);
          }
        }, 5000);
      }
      // console.log('check',res,document.getElementById(res.replace('#','')))
    };
    const goScenarios = (res) => {
      store.dispatch("setInfoNum", 2);
      router.push("/scenarios");
      if (res) {
        checkScroll(res);
        setTimeout(() => {
          if (window.checkSTimer) {
            window.clearTimeout(window.checkSTimer);
          }
        }, 5000);
      }
    };
    const checkScroll = (res) => {
      let getDom = document.getElementById(res.replace("#", ""));
      if (getDom) {
        let scrollTop = getDom.offsetTop;
        window.scrollTo(0, scrollTop);
      } else {
        window.checkSTimer = setTimeout(() => {
          checkScroll(res);
        }, 20);
      }
    };

    return { goProduct, goJoin, goScenarios, ...toRefs(state) };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
a {
  cursor: pointer;
  text-decoration: none;
}
</style>
