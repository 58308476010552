<template>
  <div class="header">
    <div class="header-left">
      <img class="header-left-logo" :src="image.logo" />
    </div>

      <div class="header-right">
      <div class="header-right-name flex-r-sb">
          <img style="width:20%;margin-top:50px" @click="menuActiveHander()" :src="image.menu" />
         
      </div>
    </div>

    <div class="menu" v-if="menuActive">
      <el-menu
        :default-active="activeTab"
        :router="true"
        :show-timeout="100"
        active-text-color="#29bdb9"
        background-color="rgba(0,0,0,0.6)"
        class="el-menu-demo"
        mode="horizontal"
        @select="menuSelectHandler"
        ref="menu"
        text-color="#fff"
      >
        <el-menu-item index="1" route="/">首页</el-menu-item>
        <el-menu-item index="2" route="/products">产品</el-menu-item>
        <el-menu-item index="2" route="/scenarios">服务</el-menu-item>
        <el-menu-item index="3" route="/about">关于我们</el-menu-item>
        <el-menu-item index="7" route="/connect">联系我们</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      activeTab: "1",
      menuActive: false,
        image: {
        logo: require("@/assets/images/logo.png"),
         menu: require("@/assets/common/menu.png"),
      },
    });
    onMounted(() => {});
    const toHome = (index, toPath) => {
      // store.dispatch("setInfoNum", index);
      router.push("/");
    };
    const menuActiveHander = (index, toPath) => {
      state.menuActive = !state.menuActive;
    };
    const menuSelectHandler = () => {
      state.menuActive = false;
    };

    return { toHome, menuActiveHander, menuSelectHandler, ...toRefs(state) };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.header {
  .inner {
    margin: 0px auto;
    height: 60px;
    padding: 0px 10px 0px 0px;
    .logo {
      img {
        height: 45px;
      }

      float: left;
      margin: 6px 30px 0px 10px;
    }
    .fr {
      color: #fff;
      float: right;
      height: 60px;
      line-height: 60px;
      transition: transform 0.3s;
      &.active {
        transform: rotate(-90deg);
      }
      .iconfont {
        font-size: 30px;
      }
    }
  }
  .el-menu-item {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
  }
  
}
</style>
