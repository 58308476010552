<template>
  <div class="header">
    <div style="text-align: center">
      <div class="header-left">
        <img class="header-left-logo" :src="image.logo" @click="goHome" />
<!--        <span class="header-left-text">中智国际人才资源服务有限公司</span>-->
      </div>
      <div class="header-right">
        <div class="header-right-name flex-r-sb">
          <div
            v-for="(item, index) in list"
            :key="item.index"
            @click="goPage(index, item.link)"
          >
            <div
              class="right-name-xiahua"
              :style="
                index === isActiveNum ? 'color:#0B318F;font-weight: bold;' : ''
              "
            >
              {{ item.menu }}
              <div
                :style="[
                  index === isActiveNum
                    ? 'color:#3E8BFF;box-shadow: 0 4px 6px 0 rgba(0,115,255,0.20);border-radius: 4px;text-align: center;height:0.2px; position: relative;top:-20px'
                    : 'display: none;',
                ]"
              >
                ━━
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      isActiveNum: store.getters.infoNum,
      num: "",
      list: [
        { menu: "首页", link: "/" },
        { menu: "产品", link: "/products" },
        { menu: "服务", link: "/scenarios" },
        { menu: "关于我们", link: "/about" },
        { menu: "联系我们", link: "/connect" },
      ],
      image: {
        logo: require("@/assets/images/logo.png"),
        frontPage: require("@/assets/common/frontPage_.png"),
      },
    });
    onMounted(() => {
      document.documentElement.scrollTop = 0;
      router.push("/");
      // state.num = sessionStorage.getItem("setInfoNum");

      // window.addEventListener("beforeunload", () => {
      //   sessionStorage.setItem(
      //     "setInfoNum",
      //     router.currentRoute.value.fullPath
      //   );
      // });


      // if (state.num === "/") {
      //   state.isActiveNum = 0;
      // } else if (state.num === "/products") {
      //   state.isActiveNum = 1;
      // } else if (state.num === "/scenarios") {
      //   state.isActiveNum = 2;
      // } else if (state.num === "/about") {
      //   state.isActiveNum = 3;
      // } else if (state.num === "/connect") {
      //   state.isActiveNum = 4;
      // }

      // if (state.num === "/products#survey") {
      //   state.isActiveNum = 1;
      //   router.push("/products");
      // } else if (state.num === "/products#360") {
      //   state.isActiveNum = 1;
      //   router.push("/products");
      // } else if (state.num === "/products#exam") {
      //   state.isActiveNum = 1;
      //   router.push("/products");
      // } else if (state.num === "/products#interview") {
      //   state.isActiveNum = 1;
      //   router.push("/products");
      // }
    });
    watch(
      () => store.getters.infoNum,
      (newVal, oldVal) => {
        state.isActiveNum = newVal;
      }
    );
    const goHome = () => {
      router.push("/");
      state.isActiveNum = 0;
    };
    const goPage = (index, toPath) => {
      store.dispatch("setInfoNum", index);
      sessionStorage.setItem("setInfoNum", router.currentRoute.value.fullPath);
      router.push(toPath);
    };

    return { goPage, goHome, ...toRefs(state) };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>

