export const footItems = [
  {
    label: '产品', value: ['心理测评', '360', '在线考试', '在线面试']
  },
  {
    label: '服务', value: ['批量招聘', '人才盘点与选拔', '在线考绩效管理与评估试',]
  },

  {
    label: '关于我们', value: ['我们是谁', '加入我们']
  },
  {
    label: '联系我们', value: ['地址', '北京市朝阳区汉威大厦西座1201', '电话', '010 - 8288 8888', '邮箱', 'ciic1234@ciic.com']
  },
]