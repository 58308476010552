import { createRouter, createMemoryHistory } from 'vue-router'

const routes = [
  { path: '/:catchAll(.*)', redirect: '/404', hidden: true },
  {
    path: '/',
    name: '首页',
    component: () => import('@/views/frontPage/index.vue')
  },
  {
    path: '/products',
    name: '产品',
    component: () => import('@/views/products/index.vue')
  },

  {
    path: '/scenarios',
    name: '服务',
    component: () => import('@/views/scenarios/index.vue')
  },
  {
    path: '/about',
    name: '关于我们',
    component: () => import('@/views/aboutUs/index.vue')
  },
  {
    path: '/connect',
    name: '联系我们',
    component: () => import('@/views/connect/index.vue')
  },
  // {
  //   path: '/join',
  //   name: '加入我们',
  //   component: () => import('@/views/joinUs/index.vue'),
  //   meta: {
  //     hideHeader: true, //隐藏头: 真
  //   },
  // },
]
// const routea = [
//   {
//     path: '/join',
//     name: '加入我们',
//     component: () => import('@/views/joinUs/index.vue')
//   },
// ]
const router = createRouter({
  history: createMemoryHistory(),
  routes,
  // routea
})

export default router
