<template>
  <div class="main-box" :class="{ isMobile: isMobile }" :style="styleObject">
    <div class="main-container">
      <Header v-show="!$route.meta.hideHeader" />
      <router-view />
      <Footer />
    </div>
  </div>
</template>
<script>
import { onMounted, toRefs, reactive } from "vue";
import Header from "@/components/head/index.vue";
import Footer from "@/components/bottom-footer/index.vue";
import Mobile from "@/components/head/mobile-header.vue";
import { useRouter } from "vue-router";
import { checkMobile, mobileScale } from "@/utils/mobile-device-adapter";
export default {
  components: {
    Header,
    Footer,
    Mobile,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      isMobile: false,
      styleObject: {
        transform: "",
      },
      isShow: false,
    });

    const mobileResize = () => {
      if (state.isMobile) {
        state.styleObject.transform = mobileScale();
      } else {
        state.styleObject.transform = "";
      }
    };
    onMounted(() => {
      state.isMobile = checkMobile();
      mobileResize();
      window.onresize = () => {
        state.isMobile = checkMobile();
        mobileResize();
      };
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.main-box {
  height: 100%;
  min-width: 1260px;
  .main-container {
    padding-top: 0px;
    text-align: center;
    // min-height: 650px;
  }
}
</style>