import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import Vuex from 'vuex'

import '@/styles/index.scss'
import '@/styles/class-styles/index.scss' // global css 直接可以写在class里的全局样式
// 通用组件全局注册
import installComponents from '@/components'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import store from '@/store/index.js'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)
// app.use(Vuex)
app.mount('#app')




installComponents.map((component) => app.component(component.name, component))
