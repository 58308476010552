export default {
    state: () => ({
        infoNum: 0,
    }),
    getters: {
        infoNum(state) {
            return state.infoNum
        },
    },
    mutations: {
        SET_INFONUM(state, params) {
            state.infoNum = params
        },
    },
    actions: {
        setInfoNum(context, params) {
            context.commit('SET_INFONUM', params)
        },

    }
}
